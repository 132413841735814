<template>
  <v-container>
    <v-card>

      <v-snackbar v-model="successFlash" :timeout="5000" top>
        <span>{{ this.successMessage }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn color="primary" text v-bind="attrs" @click="successFlash = false">Close</v-btn>
        </template>
      </v-snackbar>

      <v-snackbar v-model="errorFlash" :timeout="5000" top>
        <span>{{ this.errorMessage }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn color="primary darken-1" text v-bind="attrs" @click="errorFlash = false">
            Close</v-btn>
        </template>
      </v-snackbar>

      <!-- <v-card-title v-if="stores.length > 0" class="mb-2">Stores</v-card-title>
      <v-col cols="3" md="3">
        <v-select
          :items="stores"
          label="Select Store"
          item-text="name"
          item-value="_id"
          v-model="selectedStore"
          :value="store"
        >
        </v-select>
      </v-col> -->

      <v-card-title v-if="stores.length > 0" class="mb-2">Stores</v-card-title>
      <v-radio-group v-model="selectedStore">
        <div class="radio_group" v-if="stores.length > 0">
          <v-col v-for="(store, index) in stores" :key="index" cols="12" sm="6" md="4" lg="3">
            <v-radio :label="store.name" :value="store" @change="handleStoreChange(store)"></v-radio>
          </v-col>
        </div>
      </v-radio-group>

      <v-card-title class="mb-2">Choose a Point of Sale (POS)</v-card-title>
      <v-radio-group v-model="selectedPos">
        <div class="radio_group">
          <v-col v-for="(pos, index) in pos" :key="index" cols="2">
            <v-radio :label="pos" :value="pos._id"  @change="handlePosChange(pos)"></v-radio>
          </v-col>
        </div>
      </v-radio-group>
      <!-- <v-divider class="my-4" v-if="stores.length > 0"></v-divider> -->

      <!-- Store Display Section -->

      <!-- <v-card-title v-if="stores.length > 0" class="mb-2">Stores</v-card-title>
      <v-radio-group v-model="selectedStore">
        <div class="radio_group" v-if="stores.length > 0">
          <v-col v-for="(store, index) in stores" :key="index" cols="12" sm="6" md="4" lg="3">
            <v-radio :label="store.name" :value="store"></v-radio>
          </v-col>
        </div>
      </v-radio-group> -->

      <v-divider class="my-4" v-if="stores.length > 0"></v-divider>
      <!-- Tabs -->

      <div v-if="selectedStore">
        <v-tabs v-model="selectedTab" color="deep-purple-accent-4" align-tabs="center">
          <v-tab v-if="pos.length > 0 && pos != 'Gluggler'" :value="1"> EXPORT: Product & Stock Inventory</v-tab>
          <v-tab :value="2"> IMPORT: Product & Stock Inventory</v-tab>
          <v-tab :value="3"> UPLOAD: Product Inventory Image</v-tab>
        </v-tabs>
        <v-window v-model="selectedTab">
          <v-window-item>
            <v-card-title class="mb-2">Export Clover Inventory for Gluggler</v-card-title>
            <v-card-text>
              <div>Selected Store: {{ selectedStore.name }}</div>
              <div>Merchant ID: {{ selectedStore.clover_merchant_id }}</div>
              <div>Address: {{ selectedStore.address }}</div>
              <div>Store Type: {{ selectedStore.is_tobacco_store ? 'Tobacco Store' : 'Tobacco and Liquor Store' }}</div>
              <div>City: {{ selectedStore.city }}</div>
            </v-card-text>
            <v-card-actions class="d-flex">
              <v-btn class="custom_button" color="primary" @click="exportInventory(selectedStore._id)">Export</v-btn>
            </v-card-actions>
            <v-overlay :value="loading">
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
          </v-window-item>

          <v-window-item>
            <v-card-title class="mb-2">Import Clover inventory in Gluggler's structure</v-card-title>
            <v-card-text>
              <div>Selected Store: {{ selectedStore.name }}</div>
              <div>Merchant ID: {{ selectedStore.clover_merchant_id }}</div>
              <div>Address: {{ selectedStore.address }}</div>
              <div>Store Type: {{ selectedStore.is_tobacco_store ? 'Tobacco Store' : 'Tobacco and Liquor Store' }}</div>
              <div>City: {{ selectedStore.city }}</div>
            </v-card-text>
            <v-col cols="3" md="3">
              <v-file-input v-model="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                show-size show-size-hint :counter="false" label="Select File" @change="handleFileChange">
                <v-icon slot="prepend">mdi-file</v-icon>
              </v-file-input>
            </v-col>
            <v-card-actions class="d-flex">
              <v-btn class="custom_button" @click="inventoryImport" color="primary">Import</v-btn>
            </v-card-actions>
            <v-overlay :value="loading">
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
          </v-window-item>

          <v-window-item>
            <v-card-title class="mb-2">Inventory image Upload </v-card-title>
            <v-col cols="3" md="3">
              <v-file-input v-model="images" accept=".png, .jpg, .jpeg" multiple show-size show-size-hint :counter="false"
                label="Select Image Files" @change="handleImageChange">
                <v-icon slot="prepend">mdi-image</v-icon>
                <v-icon slot="append" v-if="images.length > 0" @click="removeAllImages">mdi-delete</v-icon>
              </v-file-input>
              <h5 class="imagesSize-text">
                Note: You can upload a maximum of 100 images at a time due to server
                limitations.
              </h5>
            </v-col>
            <v-card-actions class="d-flex">
              <v-btn @click="uploadimages" class="custom_button" color="primary">Upload</v-btn>
            </v-card-actions>

            <v-overlay :value="loading">
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
          </v-window-item>
        </v-window>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import { saveAs } from "file-saver";

export default {
  data() {
    return {
      file: null,
      pos: [],
      stores: [],
      moduleOptions: ["Products"],
      moduleName: null,
      selectedStore: null,
      maxImages: 100,
      selectedPos: null,
      showStoresList: false,
      images: [],
      selectedTab: 0,
      loading: false,
      errorMessage: "",
      successMessage: "",
      successFlash: false,
      errorFlash: false
    };
  },
  methods: {
    handleImageChange(event) {
      if (event.length > this.maxImages) {
        this.errorFlash = true;
        this.errorMessage = `You can select a maximum of ${this.maxImages} images.`;
        this.images = [];
        return;
      } else {
        this.images = event;
      }
    },
    handleFileChange(event) {
      this.file = event;
    },
    removeFile() {
      this.file = null;
    },
    removeAllImages() {
      this.images = [];
    },
    handlePosChange(pos) {
      let posName = pos;
    },
    handleStoreChange(store) {
      let StoreId = store._id;
      this.fetchPosData(StoreId);
    },
    getStoreList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "store/getAllList")
        .then((response) => {
          if (response.status === 200) {
            this.stores = response.data.Data;
            this.showStoresList = true;
            console.log("stores", this.stores);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fetchPosData(StoreId) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `setting/external_pos/ByStore/${StoreId}`)
        .then((response) => {
          if (response.status == 200) {
            this.pos = response.data.Data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportInventory(storeId) {
      this.loading = true;

      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
          `clover/inventory/gluggler_structure/${storeId}`, {
          responseType: "blob",
        }
        )
        .then((response) => {
          this.loading = false;

          if (response.status === 200) {
            const contentType = response.headers["content-type"];
            let fileExtension = "";

            if (contentType === "text/csv") {
              fileExtension = "csv";
            } else if (
              contentType ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) {
              fileExtension = "xlsx";
            } else {
              console.error("Unsupported file type");
              return;
            }

            const file = new Blob([response.data], {
              type: contentType,
            });

            const storeName = this.selectedStore.name;
            const fileName = `${storeName}.${fileExtension}`;
            saveAs(file, fileName);
            this.successFlash = true;
            this.successMessage = `Your export file ${fileName} has been downloaded. Please check your browser's download section.`;
          } else {
            console.error("Failed to download file");
            this.errorFlash = true;
            this.errorMessage = "Failed to download file";
          }

          if (response.data.statusCode === 404) {
            console.error("Failed to export file");
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error(error, "Failed to export file");
          this.errorFlash = true;
          this.errorMessage = "Export inventory from Clover failed. Please check if the store is Clover authorized.";
        });
    },
    inventoryImport() {
      if (this.file) {
        this.loading = true;
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("pos", this.pos[this.selectedPos]);
        formData.append("storeId", this.selectedStore._id);
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "inventory/importer", formData)
          .then((response) => {
            console.log(response.data.statusCode);
            this.loading = false;
            let data = null;
            const storeName = this.selectedStore.name;
            if (response.data.statusCode == 400) {
              const fileName = `import_errors_response_for_.${storeName}.txt`;
              let data = response.data.data;
              const blob = new Blob([data], { type: "text/plain" });
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              this.errorFlash = true;
              this.errorMessage = `Import file ${fileName} contains errors has been downloaded. Please check your browser's download section and review it.`;
              this.file = null;
            }
            if (response.data.statusCode == 404) {
              this.errorFlash = true;
              this.errorMessage = response.data.data;
            }
            if (response.data.statusCode == 200) {
              const fileName = `import_success_response_for_${storeName}.json`;
              let data = response.data.data;
              const jsonData = JSON.stringify(data, null, 2);
              const blob = new Blob([jsonData], { type: "application/json" });
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              this.successFlash = true;
              this.successMessage = `Your import file ${fileName} has been downloaded. Please check your browser's download section.`;
              this.file = null;
            }
          })
          .catch((error) => {
            this.loading = false;
          });
      } else {
        this.errorFlash = true;
        this.errorMessage = `Please select a file to import.`;
      }
    },
    uploadimages() {
      if (this.images) {
        this.loading = true;
        let formData = new FormData();
        for (let i = 0; i < this.images.length; i++) {
          formData.append("images[]", this.images[i]);
        }
        formData.append("moduleName", this.moduleName);
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL + "product/importInventory/imageUpload",
            formData
          )
          .then((response) => {
            this.loading = false;
            if (response.data.statusCode == 404) {
              this.errorFlash = true;
              this.errorMessage = `Product images upload failed`;
            }
            if (response.data.statusCode == 200) {
              this.successFlash = true;
              this.successMessage = `Product images uploaded successfully`;
              this.images = [];
            }
          })
          .catch((error) => {
            this.loading = false;
            console.error(error);
            this.errorFlash = true;
            this.errorMessage = `Product images upload failed`;
          });
      } else {
        this.errorFlash = true;
        this.errorMessage = "File is not selected , please select the file to import";
      }
    },
  },
  mounted() {
    this.getStoreList();
  },
};
</script>

<style scoped>
.radio_group {
  width: 100%;
  display: flex;
}

.custom_button {
  display: flex;
  margin: 0 auto;
}
</style>
